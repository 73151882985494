import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import BondApi from '../apis/BondApi';

export const loadBonds = createAsyncThunk("bonds/loadBonds", async () => {
  const bonds = await BondApi.getBonds();
  return bonds;
});
export const updateBond = createAsyncThunk("bonds/updateBond", async (bond) => {
  await BondApi.updateBond(bond);
  return bond;
});
export const addBond = createAsyncThunk("bonds/addBond", async (bond) => {  
  const bondCreated = await BondApi.addBond(bond);
  return bondCreated;
});
export const deleteBond = createAsyncThunk("bonds/deleteBond", async (bondId) => {
  const bonds = await BondApi.deleteBond(bondId);
  return bonds;
});

export const bondSlice = createSlice({
  name: "bonds",
  initialState: {
    data: [ ],
    status: "idle",
    selectedBond: null,
    error: null,
  },
  reducers: {
    selectBond: (state, action) => {
      state.status = 'idle';
      state.selectedBond = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadBonds.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadBonds.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(loadBonds.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateBond.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateBond.fulfilled, (state, action) => {        
        state.status = "succeeded";
        const idx = state.data.findIndex((u) => u.id === action.payload.id);
        if (idx !== -1) {
            state.data[idx] = action.payload;
        }
        state.selectedBond = null;
      })
      .addCase(updateBond.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.data.message;
      })
      .addCase(addBond.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addBond.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.selectedBond = null;
        state.data.push(action.payload);
      })
      .addCase(addBond.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteBond.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteBond.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(deleteBond.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });      
  },
});

export const selectBondsData = (state) => state.bonds.data;
export const selectBondsStatus = (state) => state.bonds.status;
export const selectSelectedBond = (state) => state.bonds.selectedBond;
export const { selectBond } = bondSlice.actions;
export default bondSlice.reducer;
