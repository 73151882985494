import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import BondSubscriberExchangePropApi from '../apis/BondSubscriberExchangePropApi';

export const loadExchangeConfigurations = createAsyncThunk("exchangeConfigurations/loadExchangeConfigurations", async () => {
  const configurations = await BondSubscriberExchangePropApi.getAllExchangeConfigurations();
  return configurations;
});
export const addExchangeConfiguration = createAsyncThunk("exchangeConfigurations/addExchangeConfigurations", async (configuration) => {  
  const configurationCreated = await BondSubscriberExchangePropApi.createExchangeConfiguration(configuration);
  return configurationCreated;
});
export const updateExchangeConfiguration = createAsyncThunk("exchangeConfigurations/updateExchangeConfigurations", async (configuration) => {
  await BondSubscriberExchangePropApi.updateExchangeConfiguration(configuration);
  return configuration;
});
export const deleteExchangeConfiguration = createAsyncThunk("exchangeConfigurations/deleteExchangeConfigurations", async (id) => {
  const configurations = await BondSubscriberExchangePropApi.deleteExchangeConfiguration(id);
  return configurations;
});

export const bondSubscriberExchangePropSlice = createSlice({
  name: "exchangeConfigurations",
  initialState: {
    data: [ ],
    status: "idle",
    selectedExchangeConfiguration: null,
    error: null,
  },
  reducers: {
    selectExchangeConfiguration: (state, action) => {
      state.status = 'idle';
      state.selectedExchangeConfiguration = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadExchangeConfigurations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadExchangeConfigurations.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(loadExchangeConfigurations.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addExchangeConfiguration.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addExchangeConfiguration.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.selectedBond = null;
        state.data.push(action.payload);
      })
      .addCase(addExchangeConfiguration.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateExchangeConfiguration.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateExchangeConfiguration.fulfilled, (state, action) => {        
        state.status = "succeeded";
        const idx = state.data.findIndex((u) => u.id === action.payload.id);
        if (idx !== -1) {
            state.data[idx] = action.payload;
        }
        state.selectedExchangeConfiguration = null;
      })
      .addCase(updateExchangeConfiguration.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.data.message;
      })
      .addCase(deleteExchangeConfiguration.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteExchangeConfiguration.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(deleteExchangeConfiguration.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });       
  },
});

export const selectExchangeConfigurationData = (state) => state.exchangeConfigurations.data;
export const selectExchangeConfigurationStatus = (state) => state.exchangeConfigurations.status;
export const selectSelectedExchangeConfiguration = (state) => state.exchangeConfigurations.selectedExchangeConfiguration;
export const { selectExchangeConfiguration } = bondSubscriberExchangePropSlice.actions;
export default bondSubscriberExchangePropSlice.reducer;
