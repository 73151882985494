import { Container } from 'react-bootstrap';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadExchangeConfigurations, 
         selectExchangeConfigurationStatus, 
         selectSelectedExchangeConfiguration } from '../state/bondSubscriberExchangePropSlice';
import { loadSubscribers, selectSubscriberStatus } from '../state/subscriberSlice';
import ExchangeConfigurationForm from '../components/exchangeConfiguration/ExchangeConfigurationForm';
import ExchangeConfigurationList from '../components/exchangeConfiguration/ExchangeConfigurationList';

export default function ExchangeConfiguration()  {
  const configurationState = useSelector(selectExchangeConfigurationStatus);
  const subscriberState = useSelector(selectSubscriberStatus);
  const selectedConfiguration = useSelector(selectSelectedExchangeConfiguration);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (subscriberState === "idle") {
      dispatch(loadSubscribers());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriberState]);

  useEffect(() => {
    if (configurationState === "idle") {
      dispatch(loadExchangeConfigurations());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriberState, configurationState]);

  if (configurationState === 'loading') {
    return <p>Loading...</p>
  }
  return (
    <Container>
      <h2>Portal exchange configurations admin page</h2>         
      <ExchangeConfigurationList/>
      { selectedConfiguration && <ExchangeConfigurationForm/> }
    </Container>
  );

}