import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Form, Button } from "react-bootstrap";
import { selectSelectedInvitation, selectInvitation} from '../../state/invitationSlice';
import { selectSubscribersData } from '../../state/subscriberSlice';

export default function InvitationForm() {
    const invitation = useSelector(selectSelectedInvitation);
    const [name, setName] = useState(invitation.name);
    const [email, setEmail] = useState(invitation.email);
    const [subject, setSubject] = useState(invitation.subject);
    const [message, setMessage] = useState(invitation.message);
    const [subscriberId, setSubscriberId] = useState(invitation.subscriberId || '');
    const subscribers = useSelector(selectSubscribersData);
    const dispatch = useDispatch();

    useEffect(() => {
      setName(invitation.name);
      setEmail(invitation.email);
      setSubject(invitation.subject);
      setMessage(invitation.message);
      setSubscriberId(invitation.subscriberId ?? '');
    }, [invitation]);

    function handleOnCancel() {
      dispatch(selectInvitation(null));
    }

    return (
      <Container className="mt-3">
        <Row>
          <Form>
            <h3>Invitation Data</h3>
            <Form.Group>
              <Form.Label>Name:</Form.Label>
              <Form.Control value={name} onChange={(event) => setName(event.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email:</Form.Label>
              <Form.Control type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Subject:</Form.Label>
              <Form.Control value={subject} onChange={(event) => setSubject(event.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Message:</Form.Label>
              <Form.Control as="textarea" rows={3} value={message} onChange={(event) => setMessage(event.target.value)} />
            </Form.Group>            
            <Form.Group>
              <Form.Label>Subscriber</Form.Label>
              <Form.Select value={subscriberId} onChange={(event) => setSubscriberId(event.target.value)}>
                <option value=''>Select a subscriber</option>
                {subscribers.map((subscriber) => (
                  <option key={subscriber.id} value={subscriber.id}>{subscriber.name}</option>
                ))}
              </Form.Select>
            </Form.Group>    
            <Button variant="danger" onClick={handleOnCancel} className="mt-3">Cancel</Button>        
          </Form>
        </Row>
      </Container>
    );
  }