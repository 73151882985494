import { Container } from 'react-bootstrap';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadUsers, selectUsersStatus, selectSelectedUser } from '../state/userSlice';
import UserForm from '../components/user/UserForm';
import UserList from '../components/user/UserList';
import { loadSubscribers, selectSubscriberStatus } from '../state/subscriberSlice';

export default function User() {
  const userState = useSelector(selectUsersStatus);
  const subscriberState = useSelector(selectSubscriberStatus);
  const selectedUser = useSelector(selectSelectedUser);
  const dispatch = useDispatch();
        
  useEffect(() => {
    if (subscriberState === "idle") {
      dispatch(loadSubscribers());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriberState]);

  useEffect(() => {
    if (userState === "idle") {
      dispatch(loadUsers());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriberState, userState]);

  if (userState === 'loading') {
    return <p>Loading...</p>
  }
  return (
    <Container>
      <h2>Portal User admin page</h2>         
      <UserList/>
      { selectedUser && <UserForm/> }
    </Container>
  );
}