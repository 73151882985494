import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SubscriberApi from '../apis/SubscriberApi';

export const loadSubscribers = createAsyncThunk("subscribers/loadSubscribers", async () => {
  const subscribers = await SubscriberApi.getSubscribers();
  return subscribers;
});
export const updateSubscriber = createAsyncThunk("subscribers/updateSubscriber", async (subscriber) => {
  await SubscriberApi.updateSubcriber(subscriber);
  return subscriber;
});
export const addSubscriber = createAsyncThunk("subscribers/addSubscriber", async (subscriber) => {  
  const subscriberCreated = await SubscriberApi.addSubscriber(subscriber);
  return subscriberCreated;
});
export const deleteSubscriber = createAsyncThunk("subscribers/deleteSubscriber", async (subscriberId) => {
  const subscribers = await SubscriberApi.deleteSubscriber(subscriberId);
  return subscribers;
});
export const subscriberSlice = createSlice({
  name: "subscribers",
  initialState: {
    data: [],
    status: "idle",
    selectedSubscriber: null,
    error: null
  },
  reducers: {
    selectSubscriber: (state, action) => {
      state.selectedSubscriber = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadSubscribers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadSubscribers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(loadSubscribers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateSubscriber.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateSubscriber.fulfilled, (state, action) => {        
        state.status = "succeeded";
        const idx = state.data.findIndex((u) => u.id === action.payload.id);
        if (idx !== -1) {
            state.data[idx] = action.payload;
        }
        state.selectedSubscriber = null;
      })
      .addCase(updateSubscriber.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addSubscriber.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addSubscriber.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.selectedSubscriber = null;
        state.data.push(action.payload);
      })
      .addCase(addSubscriber.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteSubscriber.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteSubscriber.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(deleteSubscriber.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectSubscribersData = (state) => state.subscribers.data;
export const selectSubscriberStatus = (state) => state.subscribers.status;
export const selectSelectedSubscriber = (state) => state.subscribers.selectedSubscriber;
export const { selectSubscriber } = subscriberSlice.actions;
export default subscriberSlice.reducer;
