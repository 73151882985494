import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Form, Button } from "react-bootstrap";
import { addContainer, selectSelectedContainer, selectContainer, updateContainer } from '../../state/containerSlice';
import { selectCredentialsData } from '../../state/credentialSlice';

export default function ContainerForm() {
    const container = useSelector(selectSelectedContainer);
    const [url, setUrl] = useState(container.url);
    const [credentialName, setCredentialName] = useState(container.credentialName);
    const credentials = useSelector(selectCredentialsData);
    const dispatch = useDispatch();

    useEffect(() => {
      setUrl(container.url);
      setCredentialName(container.credentialName);
    }, [container]);

    function handleEditClick() {    
      const containerUpdated = {
        id: container.id,
        url,
        credentialName,
      }
      dispatch(updateContainer(containerUpdated));
    }
    function handleAddClick() {
      dispatch(addContainer({
        url,
        credentialName,
      }));
    }
    function handleOnCancel() {
      dispatch(selectContainer(null));
    }

    return (
      <Container className="mt-3">
        <Row>
          <Form>
            <h3>Container Data</h3>
            <Form.Group>
              <Form.Label>Url:</Form.Label>
              <Form.Control value={url} onChange={(event) => setUrl(event.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Credential Name:</Form.Label>
              <Form.Select value={credentialName} onChange={(event) => setCredentialName(event.target.value)}>
                <option value=''>Select a credential</option>
                {credentials.map((credential) => (
                  <option key={credential.id} value={credential.id}>{credential.name}</option>
                ))}
              </Form.Select>
            </Form.Group>            
            { container.id ? (
              <Button variant="primary" onClick={handleEditClick} className="mt-3">Confirm</Button>
            ) : (
              <Button variant="primary" onClick={handleAddClick} className="mt-3">Confirm</Button>
            ) }
              &nbsp;&nbsp;<Button variant="danger" onClick={handleOnCancel} className="mt-3">Cancel</Button>
          </Form>
        </Row>
      </Container>
    );
  }