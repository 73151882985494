import api from "./config";

export const SubscriberApi = {
  getSubscribers: async function () {
    const response = await api.request({
      url: 'subscribers',
      method: 'GET',
    });
    return response.data.data;
  },
  updateSubcriber: async function (subscriber) {
    await api.request({
      url: `subscribers/${subscriber.id}`,
      method: 'PUT',
      data: subscriber,
    });
    return subscriber;
  },
  addSubscriber: async function (subscriber) {
    const response = await api.request({
      url: 'subscribers',
      method: 'POST',
      data: subscriber,
    });
    return response.data.data;
  },
  deleteSubscriber: async function (subscriberId) {
    const response = await api.request({
      url: `subscribers/${subscriberId}`,
      method: 'DELETE'
    }); 
    return response.data.data;   
  },
};
export default SubscriberApi;