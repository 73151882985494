import api from "./config";

export const BondApi = {
  getBonds: async function () {
    const response = await api.request({
      url: 'bonds',
      method: 'GET',
    });
    return response.data.data;
  },
  updateBond: async function (bond) {
    await api.request({
      url: `bonds/${bond.id}`,
      method: 'PUT',
      data: bond,
    });
    return bond;
  },
  addBond: async function (bond) {
    const response = await api.request({
      url: 'bonds',
      method: 'POST',
      data: bond,
    });
    return response.data.data;
  },
  deleteBond: async function (bondId) {
    const response = await api.request({
      url: `bonds/${bondId}`,
      method: 'DELETE'
    }); 
    return response.data.data;   
  },
};
export default BondApi;