import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ContainerApi from '../apis/ContainerApi';

export const loadContainers = createAsyncThunk("containers/loadContainers", async () => {
  const containers = await ContainerApi.getContainers();
  return containers;
});
export const updateContainer = createAsyncThunk("containers/updateContainer", async (container) => {
  await ContainerApi.updateContainer(container);
  return container;
});
export const addContainer = createAsyncThunk("containers/addContainer", async (container) => {  
  const containerCreated = await ContainerApi.addContainer(container);
  return containerCreated;
});
export const deleteContainer = createAsyncThunk("containers/deleteContainer", async (containerId) => {
  const containers = await ContainerApi.deleteContainer(containerId);
  return containers;
});

export const containerSlice = createSlice({
  name: "containers",
  initialState: {
    data: [ ],
    status: "idle",
    selectedContainer: null,
    error: null,
  },
  reducers: {
    selectContainer: (state, action) => {
      state.selectedContainer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadContainers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadContainers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(loadContainers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateContainer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateContainer.fulfilled, (state, action) => {        
        state.status = "succeeded";
        const idx = state.data.findIndex((u) => u.id === action.payload.id);
        if (idx !== -1) {
            state.data[idx] = action.payload;
        }
        state.selectedContainer = null;
      })
      .addCase(updateContainer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addContainer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addContainer.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.selectedContainer = null;
        state.data.push(action.payload);
      })
      .addCase(addContainer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteContainer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteContainer.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(deleteContainer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });      
  },
});

export const selectContainersData = (state) => state.containers.data;
export const selectContainersStatus = (state) => state.containers.status;
export const selectSelectedContainer = (state) => state.containers.selectedContainer;
export const { selectContainer } = containerSlice.actions;
export default containerSlice.reducer;
