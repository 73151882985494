import { useKeycloak } from "@react-keycloak/web";
import { Button } from 'react-bootstrap';

const LoginButton = () =>  {
    const { keycloak, initialized } = useKeycloak();

    const handleLogin = () => {
      keycloak.login();
    };
    if (initialized && !keycloak.authenticated) {
        return (
            <Button onClick={handleLogin} color="primary">
                Login
            </Button>
        );    
    }
    return null;
}

export default LoginButton;
