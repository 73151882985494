import api from "./config";

export const ContainerApi = {
  getContainers: async function () {
    const response = await api.request({
      url: 'containers',
      method: 'GET',
    });
    return response.data;
  },
  updateContainer: async function (container) {
    await api.request({
      url: `containers/${container.id}`,
      method: 'PUT',
      data: container,
    });
    return container;
  },
  addContainer: async function (container) {
    const response = await api.request({
      url: 'containers',
      method: 'POST',
      data: container,
    });
    return response.data;
  },
  deleteContainer: async function (containerId) {
    const response = await api.request({
      url: `containers/${containerId}`,
      method: 'DELETE'
    }); 
    return response.data   
  },
};
export default ContainerApi;