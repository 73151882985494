
import { useKeycloak } from '@react-keycloak/web';
import Unauthorized from './Unauthorized';

const AuthRoute = ({render}) => {
  const { keycloak, initialized } = useKeycloak();

  if (initialized && keycloak.authenticated) {
    return render();
  }

  return <Unauthorized />;
};

export default AuthRoute;