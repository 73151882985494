import { Table, Button } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { selectSubscribersData } from "../../state/subscriberSlice";
import { deleteExchangeConfiguration, selectExchangeConfiguration, selectExchangeConfigurationData } from '../../state/bondSubscriberExchangePropSlice';

export default function ExchangeConfigurationList() {
  const subscribers = useSelector(selectSubscribersData);
  const configurations = useSelector(selectExchangeConfigurationData);
  const dispatch = useDispatch();

  function handleDeleteConfiguration(id) {
    const confirmed = window.confirm("Are you sure you want to delete this configuration?");
    if (confirmed) {
      dispatch(deleteExchangeConfiguration(id));
    }
  }
  function handleSelectConfiguration(configuration) {
    dispatch(selectExchangeConfiguration(configuration));
  }
  function handleCreateConfiguration() {
    dispatch(selectExchangeConfiguration({
      bondId: '',
      subscriberId: '',
      host: '',
      port: 0,
      username: '',
      password: '',
      vhost: '',
      exchangeName: '',
      subscribe: false
    }));    
  }
  return (
    <>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Id</th>
          <th>Bond Id</th>
          <th>Subscriber</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
      {configurations.map((configuration) => (
        <tr key={configuration.id}>
          <td>{configuration.id}</td>
          <td>{configuration.bondId}</td>
          <td>{(subscribers.filter((s) => s.id === configuration.subscriberId))[0]?.name }</td>
          <td>
          <Button variant="primary" onClick={() => handleSelectConfiguration(configuration)}>Edit</Button>&nbsp;&nbsp;
          <Button variant="danger" onClick={() => handleDeleteConfiguration(configuration.id)}>Delete</Button>
          </td>
        </tr>
      ))}
      </tbody>
    </Table>
    <Button variant="primary" onClick={ handleCreateConfiguration }>Create a new configuration</Button>        
    </>
  );
}