import { useKeycloak } from "@react-keycloak/web";
import { Button } from 'react-bootstrap';

const LogoutButton = () =>  {
    const { keycloak, initialized } = useKeycloak();

    const handleLogout = () => {
        keycloak.logout();
      };
    if (initialized && keycloak.authenticated) {
        return (
            <Button onClick={handleLogout} color="primary">
                Logout
            </Button>
        );    
    }  
    return null;
}

export default LogoutButton;
