import { Container } from 'react-bootstrap';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadContainers, selectContainersStatus, selectSelectedContainer } from '../state/containerSlice';
import ContainerForm from '../components/container/ContainerForm';
import ContainerList from '../components/container/ContainerList';
import { loadCredentials, selectCredentialsStatus } from '../state/credentialSlice';

export default function SecureContainer() {
  const containerState = useSelector(selectContainersStatus);
  const credentialState = useSelector(selectCredentialsStatus);
  const selectedContainer = useSelector(selectSelectedContainer);
  const dispatch = useDispatch();
        
  useEffect(() => {
    if (credentialState === "idle") {
      dispatch(loadCredentials());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentialState]);

  useEffect(() => {
    if (containerState === "idle") {
      dispatch(loadContainers());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentialState, containerState]);

  if (containerState === 'loading') {
    return <p>Loading...</p>
  }
  return (
    <Container>
      <h2>Portal Container admin page</h2>         
      <ContainerList/>
      { selectedContainer && <ContainerForm/> }
    </Container>
  );

}