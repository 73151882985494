import { configureStore  } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import subscriberSlice from './subscriberSlice';
import containerSlice from './containerSlice';
import credentialSlice from './credentialSlice';
import invitationSlice from './invitationSlice';
import bondSlice from './bondSlice';
import bondSubscriberExchangePropSlice from './bondSubscriberExchangePropSlice';

export const store = configureStore({
    reducer: {
        users: userReducer,
        subscribers: subscriberSlice,
        containers: containerSlice,
        credentials: credentialSlice,
        invitations: invitationSlice,
        bonds: bondSlice,
        exchangeConfigurations: bondSubscriberExchangePropSlice,
    }
})