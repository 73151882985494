import api from "./config";

export const BondSubscriberExchangePropApi = {
  getAllExchangeConfigurations: async function () {
    const response = await api.request({
      url: 'bondSubscriberExchangeProp',
      method: 'GET',
    });
    return response.data.data;
  },
  createExchangeConfiguration: async function (configuration) {
    const response = await api.request({
      url: 'bondSubscriberExchangeProp',
      method: 'POST',
      data: configuration,
    });
    return response.data.data;
  },
  updateExchangeConfiguration: async function (configuration) {
    await api.request({
      url: `bondSubscriberExchangeProp/${configuration.id}`,
      method: 'PUT',
      data: configuration,
    });
    return configuration;
  },
  deleteExchangeConfiguration: async function (id) {
    const response = await api.request({
      url: `bondSubscriberExchangeProp/${id}`,
      method: 'DELETE'
    }); 
    return response.data.data;   
  },
};
export default BondSubscriberExchangePropApi;