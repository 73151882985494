import { Table, Button } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { selectSubscribersData } from "../../state/subscriberSlice";
import { deleteBond, selectBond, selectBondsData } from '../../state/bondSlice';

export default function BondList() {
  const subscribers = useSelector(selectSubscribersData);
  const bonds = useSelector(selectBondsData);
  const dispatch = useDispatch();

  function handleDeleteBond(bondId) {
    const confirmed = window.confirm("Are you sure you want to delete this bond?");
    if (confirmed) {
      dispatch(deleteBond(bondId));
    }
  }
  function handleSelectBond(bond) {
    dispatch(selectBond(bond));
  }
  function handleCreateBond() {
    dispatch(selectBond({
      subscriber1Id: '',
      subscriber2Id: '',
      aliasSubscriber1ForSubscriber2: '',
      aliasSubscriber2ForSubscriber1: '',
      ownership: 1,
      status: 'PENDING'
    }));    
  }
  return (
    <>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Id</th>
          <th>Subscriber 1</th>
          <th>Subscriber 2</th>
          <th>Alias subs1 for subs2</th>
          <th>Alias subs2 for subs1</th>
          <th>Ownership</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
      {bonds.map((bond) => (
        <tr key={bond.id}>
          <td>{bond.id}</td>
          <td>{(subscribers.filter((s) => s.id === bond.subscriber1Id))[0]?.name }</td>
          <td>{(subscribers.filter((s) => s.id === bond.subscriber2Id))[0]?.name }</td>
          <td>{bond.aliasSubscriber1ForSubscriber2}</td>
          <td>{bond.aliasSubscriber2ForSubscriber1}</td>
          <td>{bond.ownership}</td>
          <td>{bond.status}</td>
          <td>
          <Button variant="primary" onClick={() => handleSelectBond(bond)}>Edit</Button>&nbsp;&nbsp;
          <Button variant="danger" onClick={() => handleDeleteBond(bond.id)}>Delete</Button>
          </td>
        </tr>
      ))}
      </tbody>
    </Table>
    <Button variant="primary" onClick={ handleCreateBond }>Create a new bond</Button>        
    </>
  );
}