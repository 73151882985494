import { Link } from 'react-router-dom';
import { Card, Container, Row } from "react-bootstrap";

export default function Home() {
  return (
      <Container>
        <h1>Welcome to the 1Bonding Administration Panel</h1>
        <>
        <p>Please select an option below to get started:</p>
        <Row>
          <div className="col-md-4 mb-3">
            <Card>
            <Card.Body>                  
                <Card.Title tag="h2"><Link to="/invitations">Manage Invitations</Link></Card.Title>
                <Card.Text>Manage the invitations of the 1Bonding platform.</Card.Text>
            </Card.Body>
            </Card>
          </div>
          <div className="col-md-4 mb-3">
            <Card>
            <Card.Body>                  
                <Card.Title tag="h2"><Link to="/users">Manage Users</Link></Card.Title>
                <Card.Text>Manage the users of the 1Bonding platform.</Card.Text>
            </Card.Body>
            </Card>
          </div>
          <div className="col-md-4 mb-3">
            <Card>
              <Card.Body>
                <Card.Title tag="h2"><Link to="/subscribers">Manage Subscribers</Link></Card.Title>
                <Card.Text>Manage the subscribers of the 1Bonding platform.</Card.Text>
              </Card.Body>
            </Card>
          </div>        
        </Row>
        <Row>
          <div className="col-md-4 mb-3">
            <Card>
              <Card.Body>
                <Card.Title tag="h2"><Link to="/bonds">Manage Bonds</Link></Card.Title>
                <Card.Text>Manage the bonds between subscribers.</Card.Text>
              </Card.Body>
            </Card>
          </div>          
          <div className="col-md-4 mb-3">
            <Card>
              <Card.Body>
                <Card.Title tag="h2"><Link to="/containers">Manage Containers</Link></Card.Title>
                <Card.Text>Manage the secure brokers.</Card.Text>
              </Card.Body>
            </Card>
          </div>          
        </Row>
        </>
      </Container>
    );
  }