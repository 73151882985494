import { useState } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import { BsPencil, BsTrash, BsCheck } from 'react-icons/bs';

export default function PropertyList({ properties, onPropertiesChange }) {
  const [newProperty, setNewProperty] = useState({ keyName: '', value: '' });
  const [editingStates, setEditingStates] = useState(Array(properties.length).fill(false));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProperty((prevProperty) => ({ ...prevProperty, [name]: value }));
  };

  const handleKeyNameChange = (e, index) => {
    const newKeyName = e.target.value;
    // Create a new copy of properties array
    const updatedProperties = [...properties]; 
    // Create a new copy of the specific property object with updated keyName
    updatedProperties[index] = { ...updatedProperties[index], keyName: newKeyName }; 
    onPropertiesChange(updatedProperties);
  }

  const handleValueChange = (e, index) => {
    const newValue = e.target.value;
    // Create a new copy of properties array
    const updatedProperties = [...properties]; 
    // Create a new copy of the specific property object with updated value
    updatedProperties[index] = { ...updatedProperties[index], value: newValue }; 
    onPropertiesChange(updatedProperties);
  }

  const addProperty = () => {
    if (newProperty.keyName.trim() !== '' && newProperty.value.trim() !== '') {
        const updatedProperties = [...properties, newProperty];
        onPropertiesChange(updatedProperties);
        setNewProperty({ keyName: '', value: '' });
      }
  };

  const removeProperty = index => {
    const newProperties = [...properties];
    newProperties.splice(index, 1);
    onPropertiesChange(newProperties);
    editingStates.fill(false);
    setEditingStates(editingStates);
  };

  const handleEditClick = (index) => {
    setEditingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = true;
      return newStates;
    });
  };
  
  const handleSaveClick = (index) => {
    // Perform save operation or update state with edited values for the specific index
    setEditingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = false;
      return newStates;
    });
  };

  return (
    <div style={{margin: '15px 0'}}>
      <hr></hr>
      <label>Enter new property</label>
      <Form.Group>
        <Form.Control
        type="text"
        name="keyName"
        value={newProperty.keyName}
        placeholder="Key Name"
        onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group>
        <Form.Control
        type="text"
        name="value"
        value={newProperty.value}
        placeholder="Value"
        onChange={handleInputChange}
        />
      </Form.Group>
      <Button variant="primary" onClick={addProperty} style={{margin: '15px 0'}}>
        Add Property
      </Button>
      <div className='properties-list'>
        <label>
          List of properties
        </label>
        {properties.map((prop, index) => (
        <Card>
          <Card.Body className="d-flex justify-content-between">
          {editingStates[index] ? (
            <div>
              <input type="text" value={prop.keyName} onChange={(e) => handleKeyNameChange(e, index)} />
              <input type="text" value={prop.value} onChange={(e) => handleValueChange(e, index)} />
            </div>
          ) : (
            <div>
              <strong>{prop.keyName}: </strong>
              {prop.value}
            </div>
          )}
            <div>
          {editingStates[index] ? (
              <Button variant="outline-success" onClick={() => handleSaveClick(index)}><BsCheck /></Button>
          ) : (
              <Button variant="outline-success" onClick={() => handleEditClick(index)}><BsPencil /></Button>
          )}
              <Button variant="outline-danger" onClick={() => removeProperty(index)}><BsTrash/></Button>
            </div>
          </Card.Body>
        </Card>
        ))}
      </div>
    </div>
  );
}
