import { Container } from 'react-bootstrap';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadInvitations, selectInvitationsStatus, selectSelectedInvitation } from '../state/invitationSlice';
import InvitationList from '../components/invitation/InvitationList';
import { loadSubscribers, selectSubscriberStatus } from '../state/subscriberSlice';
import InvitationForm from '../components/invitation/InvitationForm';

export default function Invitation() {
  const invitationState = useSelector(selectInvitationsStatus);
  const subscriberState = useSelector(selectSubscriberStatus);
  const selectedInvitation = useSelector(selectSelectedInvitation);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (subscriberState === "idle") {
      dispatch(loadSubscribers());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriberState]);

  useEffect(() => {
    if (invitationState === "idle") {
      dispatch(loadInvitations());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriberState, invitationState]);

  if (invitationState === 'loading') {
    return <p>Loading...</p>
  }
  return (
    <Container>
      <h2>Portal Invitation admin page</h2>         
      <InvitationList/>
      { selectedInvitation && <InvitationForm/> }
    </Container>
  );

}