import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Form, Button } from "react-bootstrap";
import { addUser, selectSelectedUser, selectUser, updateUser } from '../../state/userSlice';
import { selectSubscribersData } from '../../state/subscriberSlice';

export default function UserForm() {
    const user = useSelector(selectSelectedUser);
    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [email, setEmail] = useState(user.email);
    const [subscriberIds, setSubscriberIds] = useState(user.subscriberIds);
    const subscribers = useSelector(selectSubscribersData);
    const dispatch = useDispatch();

    useEffect(() => {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      setSubscriberIds(user.subscriberIds);
    }, [user]);

    function handleEditClick() {    
      const userUpdated = {
        id: user.id,
        firstName,
        lastName,
        email,
        subscriberIds
      }
      dispatch(updateUser(userUpdated));
    }
    function handleAddClick() {
      dispatch(addUser({
        firstName: firstName,
        lastName: lastName,
        email: email,
        subscriberIds
      }));
    }
    function handleOnCancel() {
      dispatch(selectUser(null));
    }  
    const handleSelectChange = (event) => {
      const selectedValues = Array.from(event.target.selectedOptions, (option) => option.value);
      setSubscriberIds(selectedValues);
    };
    
    return (
      <Container className="mt-3">
        <Row>
          <Form>
            <h3>User Data</h3>
            <Form.Group>
              <Form.Label>Name:</Form.Label>
              <Form.Control value={firstName} onChange={(event) => setFirstName(event.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Last Name:</Form.Label>
              <Form.Control value={lastName} onChange={(event) => setLastName(event.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email:</Form.Label>
              <Form.Control type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Subscriber</Form.Label>
              <Form.Select value={subscriberIds} onChange={handleSelectChange} multiple>
                <option key="no_subscriber" value="">Select a subscriber</option>
                {subscribers.map((subscriber) => (
                  <option key={subscriber.id} value={subscriber.id}>{subscriber.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
            { user.id ? (
              <Button variant="primary" onClick={handleEditClick} className="mt-3">Confirm</Button>
            ) : (
              <Button variant="primary" onClick={handleAddClick} className="mt-3">Confirm</Button>
            ) }
              &nbsp;&nbsp;<Button variant="danger" onClick={handleOnCancel} className="mt-3">Cancel</Button>
          </Form>
        </Row>
      </Container>
    );
  }