import { Table, Button } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { selectSubscribersData, deleteSubscriber, selectSubscriber } from "../../state/subscriberSlice";

export default function SubscirberList() {
  const subscribers = useSelector(selectSubscribersData);
  const dispatch = useDispatch();

  function handleDeleteSubscriber(subscriberId) {
    // TODO: Ensure that is possible delete that subscriber
    const confirmed = window.confirm("Are you sure you want to delete this subscriber?");
    if (confirmed) {
      dispatch(deleteSubscriber(subscriberId));
    }
  }
  function handleSelectSubscriber(subscriber) {
    dispatch(selectSubscriber(subscriber));
  }
  function handleCreateSubscriber() {
    dispatch(selectSubscriber({
      name: '',
      email: '',
      address: '',
      containerId: ''
    }));    
  }

  return (
    <>
    <Table striped bordered hover>
      <thead>
        <tr>
        <th>Id</th>
          <th>Name</th>
          <th>Email</th>
          <th>Adress</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
      {subscribers.map((subscriber) => (
        <tr key={subscriber.id}>
          <td>{subscriber.id}</td>
          <td>{subscriber.name}</td>
          <td>{subscriber.email}</td>
          <td>{subscriber.address}</td>
          <td>
          <Button variant="primary" onClick={() => handleSelectSubscriber(subscriber)}>Edit</Button>&nbsp;&nbsp;
          <Button variant="danger" onClick={() => handleDeleteSubscriber(subscriber.id)}>Delete</Button>
          </td>
        </tr>
      ))}
      </tbody>
    </Table>
    <Button variant="primary" onClick={ handleCreateSubscriber }>Create a new Subscriber</Button>        
    </>
  );
}