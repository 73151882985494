import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import credentialApi from '../apis/CredentialApi';

export const loadCredentials = createAsyncThunk("credentials/loadCredentials", async () => {
  const credentials = await credentialApi.getCredentials();
  return credentials;
});

export const credentialSlice = createSlice({
    name: "credentials",
    initialState: {
      data: [ ],
      status: "idle",
      error: null,
    },
    extraReducers: (builder) => {
      builder
        .addCase(loadCredentials.pending, (state) => {
          state.status = "loading";
        })
        .addCase(loadCredentials.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.data = action.payload;
        })
        .addCase(loadCredentials.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        });
    }
});

export const selectCredentialsData = (state) => state.credentials.data;
export const selectCredentialsStatus = (state) => state.credentials.status;
export default credentialSlice.reducer;