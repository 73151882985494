import api from "./config";

export const UserApi = {
  getUsers: async function () {
    const response = await api.request({
      url: 'users',
      method: 'GET',
    });
    return response.data.data;
  },
  updateUser: async function (user) {
    await api.request({
      url: `users/${user.id}`,
      method: 'PUT',
      data: user,
    });
    return user;
  },
  addUser: async function (user) {
    const response = await api.request({
      url: 'users',
      method: 'POST',
      data: user,
    });
    return response.data.data;
  },
  deleteUser: async function (userId) {
    const response = await api.request({
      url: `users/${userId}`,
      method: 'DELETE'
    }); 
    return response.data.data;   
  },
};
export default UserApi;