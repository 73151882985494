import { Container } from 'react-bootstrap';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadSubscribers, selectSubscriberStatus, selectSelectedSubscriber } from '../state/subscriberSlice';
import { loadContainers, selectContainersStatus } from '../state/containerSlice';
import SubscriberForm from '../components/subscriber/SubscriberForm';
import SubscriberList from '../components/subscriber/SubscriberList';


export default function Subscriber() {
  const subscriberState = useSelector(selectSubscriberStatus);
  const containerState = useSelector(selectContainersStatus);
  const selectedSubscriber = useSelector(selectSelectedSubscriber);
  const dispatch = useDispatch();
        
  useEffect(() => {
    if (containerState === "idle") {
      dispatch(loadContainers());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerState]);

  useEffect(() => {
    if (subscriberState === "idle") {
      dispatch(loadSubscribers());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerState, subscriberState]);

  if (subscriberState === 'loading') {
    return <p>Loading...</p>
  }
  return (
    <Container>
      <h2>Portal Subscriber admin page</h2>         
      <SubscriberList/>
      { selectedSubscriber && <SubscriberForm/> }
    </Container>
  );
}