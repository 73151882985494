import api from "./config";

export const InvitationApi = {
  getInvitations: async function () {
    const response = await api.request({
      url: 'invitations',
      method: 'GET',
    });
    return response.data;
  }
};

export default InvitationApi;