import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Form, Button } from "react-bootstrap";
import { addSubscriber, selectSelectedSubscriber, updateSubscriber, selectSubscriber } from "../../state/subscriberSlice";
import { selectContainersData } from '../../state/containerSlice';

export default function SubscriberForm() {
  const subscriber = useSelector(selectSelectedSubscriber);  
  const containers = useSelector(selectContainersData);
  const [name, setName] = useState(subscriber.name);
  const [email, setEmail] = useState(subscriber.email);
  const [address, setAddress] = useState(subscriber.address);
  const [containerId, setContainerId] = useState(subscriber.containerId || '');
  const [canAddBond, setCanAddBond] = useState(subscriber.canAddBond);
  const dispatch = useDispatch();

  useEffect(() => {
    setName(subscriber.name ?? '');
    setEmail(subscriber.email ?? '');
    setAddress(subscriber.address ?? '');
    setContainerId(subscriber.containerId ?? '');
    setCanAddBond(subscriber.canAddBond);
  }, [subscriber]);

  function handleEditClick() {
    const subscriberUpdated = {
      id: subscriber.id,
      name,
      email,
      address,
      containerId: containerId === '' ? null : containerId,
      canAddBond
    }
    dispatch(updateSubscriber(subscriberUpdated));
  }
    function handleAddClick() {
      dispatch(addSubscriber({
        name,
        email,
        address,
        containerId: containerId === '' ? null : containerId,
        canAddBond,
      }))
    }
    function handleOnCancel() {
      dispatch(selectSubscriber(null));
    }
    return (
      <Container className="mt-3">
        <Row>
          <Form>
            <h3>Subscriber Data</h3>
            <Form.Group>
              <Form.Label>Name:</Form.Label>
              <Form.Control placeholder="Subscriber's name" value={name} onChange={(event) => setName(event.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email:</Form.Label>
              <Form.Control type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Address:</Form.Label>
              <Form.Control placeholder="Street, number, country, postal code..." value={address} onChange={(event) => setAddress(event.target.value)} />
            </Form.Group>  
            <Form.Group>
              <Form.Label>Container</Form.Label>
              <Form.Select value={containerId} onChange={(event) => setContainerId(event.target.value)}>
                <option value=''>Select a container</option>
                {containers.map((container) => (
                  <option key={container.id} value={container.id}>{container.id} - {container.url}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group>
            <Form.Check
              type="checkbox"
              label="Can Add Bond"
              checked={canAddBond}
              onChange={(event) => setCanAddBond(event.target.checked)}
            />
            </Form.Group>
            { subscriber.id ? (
              <Button variant="primary" onClick={handleEditClick} className="mt-3">Confirm</Button>
            ) : (
              <Button variant="primary" onClick={handleAddClick} className="mt-3">Confirm</Button>
            ) }
              &nbsp;&nbsp;<Button variant="danger" onClick={handleOnCancel} className="mt-3">Cancel</Button>
          </Form>
        </Row>
      </Container>
    );
  }