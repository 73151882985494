import { Table, Button } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { selectInvitationsData, selectInvitation } from "../../state/invitationSlice";
import { selectSubscribersData } from "../../state/subscriberSlice";

export default function InvitationList() {
  const invitations = useSelector(selectInvitationsData);
  const subscribers = useSelector(selectSubscribersData);
  const dispatch = useDispatch();

  function handleSelectInvitation(invitation) {
    dispatch(selectInvitation(invitation));
  }

  return (
    <>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Subscriber</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
      {invitations.map((invitation) => (
        <tr key={invitation.id}>
          <td>{invitation.name}</td>
          <td>{invitation.email}</td>
          <td>{(subscribers.filter((s) => s.id === invitation.subscriberId))[0]?.name }</td>
          <td>{invitation.status}</td>
          <td><Button variant="primary" onClick={() => handleSelectInvitation(invitation)}>View</Button></td>
        </tr>
      ))}
      </tbody>
    </Table>
    </>
  );
}