import { Table, Button } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { deleteContainer, selectContainer, selectContainersData } from '../../state/containerSlice';
import { selectCredentialsData } from "../../state/credentialSlice";

export default function ContainerList() {
  const containers = useSelector(selectContainersData);
  const credentials = useSelector(selectCredentialsData);

  const dispatch = useDispatch();

  function handleDeleteContainer(containerId) {
    const confirmed = window.confirm("Are you sure you want to delete this container?");
    if (confirmed) {
      dispatch(deleteContainer(containerId));
    }
  }
  function handleSelectContainer(container) {
    dispatch(selectContainer(container));
  }
  function handleCreateContainer() {
    dispatch(selectContainer({
      url: '',
      credentialName: '',
    }));    
  }
  return (
    <>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Id</th>
          <th>Url</th>
          <th>Credential name</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
      {containers.map((container) => (
        <tr key={container.id}>
          <td>{container.id}</td>
          <td>{container.url}</td>
          <td>{(credentials.filter((c) => c.name === container.credentialName))[0]?.name }</td>
          <td>
          <Button variant="primary" onClick={() => handleSelectContainer(container)}>Edit</Button>&nbsp;&nbsp;
          <Button variant="danger" onClick={() => handleDeleteContainer(container.id)}>Delete</Button>
          </td>
        </tr>
      ))}
      </tbody>
    </Table>
    <Button variant="primary" onClick={ handleCreateContainer }>Create a new container</Button>        
    </>
  );
}