import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LogoutButton from './LogoutButton';
import OneBondingLogo from '../1bonding_blue.svg';

const Navigation = () => {

  return (
        <Navbar bg="light" expand="lg">
          <Navbar.Brand><Link to="/"><img src={OneBondingLogo} style={{ width: '100px' }} alt="React Logo"/></Link></Navbar.Brand>
            <Nav className="ml-auto">
              <Nav.Link as={Link} to="/invitations">Invitations</Nav.Link>
              <Nav.Link as={Link} to="/users">Users</Nav.Link>
              <Nav.Link as={Link} to="/subscribers">Subscribers</Nav.Link>
              <Nav.Link as={Link} to="/bonds">Bonds</Nav.Link>
              <Nav.Link as={Link} to="/containers">Containers</Nav.Link>
              <Nav.Link as={Link} to="/configurations">Rabbit MQ Config</Nav.Link>
              <Nav.Item>
                <LogoutButton />
              </Nav.Item>
            </Nav> 
        </Navbar>
  );
};

export default Navigation;