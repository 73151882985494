import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Form, Button } from "react-bootstrap";
import { addExchangeConfiguration, 
        selectSelectedExchangeConfiguration, 
        selectExchangeConfiguration, 
        updateExchangeConfiguration, 
        selectExchangeConfigurationStatus } from '../../state/bondSubscriberExchangePropSlice';
import { selectSubscribersData } from '../../state/subscriberSlice';
import PropertyList from "../properties/PropertyList";

export default function ExchangeConfgurationForm() {
    const configuration = useSelector(selectSelectedExchangeConfiguration);
    const configurationState = useSelector(selectExchangeConfigurationStatus);
    const [bondId, setBondId] = useState(configuration.bondId);
    const [subscriberId, setSubscriberId] = useState(configuration.subscriberId);
    const [properties, setProperties] = useState(configuration.properties ?? []);

    const subscribers = useSelector(selectSubscribersData);
    const dispatch = useDispatch();

    useEffect(() => {
      setBondId(configuration.bondId);
      setSubscriberId(configuration.subscriberId);
    }, [configuration]);

    function handleEditClick() {    
      const configurationUpdated = {
        id: configuration.id,
        bondId,
        subscriberId,
        properties
      }
      dispatch(updateExchangeConfiguration(configurationUpdated));
    }
    function handleAddClick() {
      dispatch(addExchangeConfiguration({
        bondId,
        subscriberId, 
        properties       
      }));
    }
    function handleOnCancel() {
      dispatch(selectExchangeConfiguration(null));
    }
    const handlePropertiesChange = (newProperties) => {
      setProperties(newProperties);
    };
    if (configurationState === 'succeeded') {
      return (
        <Container className="mt-3">
          <Row>
            <Form>
              <h3>Rabbit MQ Configuration</h3>
              <Form.Group>
                <Form.Label>Bond id</Form.Label>
                <Form.Control value={bondId} onChange={(event) => setBondId(event.target.value)} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Subscriber</Form.Label>
                <Form.Select value={subscriberId} onChange={(event) => setSubscriberId(event.target.value)}>
                  <option value=''>Select a subscriber</option>
                  {subscribers.map((subscriber) => (
                    <option key={subscriber.id} value={subscriber.id}>{subscriber.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              { configuration.id ? (
                <Button variant="primary" onClick={handleEditClick} className="mt-3">Confirm</Button>
              ) : (
                <Button variant="primary" onClick={handleAddClick} className="mt-3">Confirm</Button>
              ) }
                &nbsp;&nbsp;<Button variant="danger" onClick={handleOnCancel} className="mt-3">Cancel</Button>
              <PropertyList properties={properties} onPropertiesChange={handlePropertiesChange}/>
            </Form>
          </Row>
        </Container>
      );
    }

    return null;
  }