import Keycloak from "keycloak-js";


const keycloak = new Keycloak({
   realm: window.REACT_APP_KEYCLOAK_REALM,
   url: window.REACT_APP_KEYCLOAK_URL,
   clientId: window.REACT_APP_KEYCLOAK_CLIENT_ID,
  });


export default keycloak;