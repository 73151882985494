import { Container } from 'react-bootstrap';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadBonds, selectBondsStatus, selectSelectedBond } from '../state/bondSlice';
import { loadSubscribers, selectSubscriberStatus } from '../state/subscriberSlice';
import BondForm from '../components/bond/BondForm';
import BondList from '../components/bond/BondList';

export default function Bond() {
  const bondState = useSelector(selectBondsStatus);
  const subscriberState = useSelector(selectSubscriberStatus);
  const selectedBond = useSelector(selectSelectedBond);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (subscriberState === "idle") {
      dispatch(loadSubscribers());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriberState]);

  useEffect(() => {
    if (bondState === "idle") {
      dispatch(loadBonds());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriberState, bondState]);

  if (bondState === 'loading') {
    return <p>Loading...</p>
  }
  return (
    <Container>
      <h2>Portal Bond admin page</h2>         
      <BondList/>
      { selectedBond && <BondForm/> }
    </Container>
  );

}