
import { Table, Button } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { deleteUser, selectUser, selectUsersData } from '../../state/userSlice';

export default function UserList() {
  const users = useSelector(selectUsersData);
  const dispatch = useDispatch();

  function handleDeleteUser(userId) {
    const confirmed = window.confirm("Are you sure you want to delete this user?");
    if (confirmed) {
      dispatch(deleteUser(userId));
    }
  }
  function handleSelectUser(user) {
    dispatch(selectUser(user));
  }
  function handleCreateUser() {
    dispatch(selectUser({
      firstName: '',
      lastName: '',
      email: '',
    }));    
  }
  return (
    <>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Last name</th>
          <th>Email</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
      {users.map((user) => (
        <tr key={user.id}>
          <td>{user.id}</td>
          <td>{user.firstName}</td>
          <td>{user.lastName}</td>
          <td>{user.email}</td>
          <td>
          <Button variant="primary" onClick={() => handleSelectUser(user)}>Edit</Button>&nbsp;&nbsp;
          <Button variant="danger" onClick={() => handleDeleteUser(user.id)}>Delete</Button>
          </td>
        </tr>
      ))}
      </tbody>
    </Table>
    <Button variant="primary" onClick={ handleCreateUser }>Create a new user</Button>        
    </>
  );
}