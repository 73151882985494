import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Form, Button } from "react-bootstrap";
import { addBond, selectSelectedBond, selectBond, updateBond, selectBondsStatus } from '../../state/bondSlice';
import { selectSubscribersData } from '../../state/subscriberSlice';

export default function BondForm() {
    const bond = useSelector(selectSelectedBond);
    const bondState = useSelector(selectBondsStatus);
    const [subscriber1Id, setSubscriber1Id] = useState(bond.subscriber1Id);
    const [subscriber2Id, setSubscriber2Id] = useState(bond.subscriber2Id);
    const [aliasSubscriber1ForSubscriber2, setAliasSubscriber1ForSubscriber2] = useState(bond.aliasSubscriber1ForSubscriber2);
    const [aliasSubscriber2ForSubscriber1, setAliasSubscriber2ForSubscriber1] = useState(bond.aliasSubscriber2ForSubscriber1);
    const [ownership, setOwnership] = useState(bond.ownership);
    const [status, setStatus] = useState(bond.status);

    const subscribers = useSelector(selectSubscribersData);
    const dispatch = useDispatch();

    useEffect(() => {
      setSubscriber1Id(bond.subscriber1Id);
      setSubscriber2Id(bond.subscriber2Id);
      setAliasSubscriber1ForSubscriber2(bond.aliasSubscriber1ForSubscriber2 ?? '');
      setAliasSubscriber2ForSubscriber1(bond.aliasSubscriber2ForSubscriber1 ?? '');
      setOwnership(bond.ownership);
      setStatus(bond.status);
    }, [bond]);

    function handleEditClick() {    
      const bondUpdated = {
        id: bond.id,
        subscriber1Id,
        subscriber2Id,
        aliasSubscriber1ForSubscriber2,
        aliasSubscriber2ForSubscriber1,
        ownership,
        status
      }
      dispatch(updateBond(bondUpdated));
    }
    function handleAddClick() {
      dispatch(addBond({
        subscriber1Id,
        subscriber2Id,
        aliasSubscriber1ForSubscriber2: aliasSubscriber1ForSubscriber2 === '' ? null : aliasSubscriber1ForSubscriber2,
        aliasSubscriber2ForSubscriber1: aliasSubscriber2ForSubscriber1 === '' ? null : aliasSubscriber2ForSubscriber1,
        ownership,
        status
      }));
    }
    function handleOnCancel() {
      dispatch(selectBond(null));
    }
    if (bondState === 'succeeded') {
      return (
        <Container className="mt-3">
          <Row>
            <Form>
              <h3>Bond Data</h3>
              <Form.Group>
                <Form.Label>Subscriber 1</Form.Label>
                <Form.Select value={subscriber1Id} onChange={(event) => setSubscriber1Id(event.target.value)}>
                  <option value=''>Select a subscriber</option>
                  {subscribers.map((subscriber) => (
                    <option key={subscriber.id} value={subscriber.id}>{subscriber.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group>
                <Form.Label>Subscriber 2</Form.Label>
                <Form.Select value={subscriber2Id} onChange={(event) => setSubscriber2Id(event.target.value)}>
                  <option value=''>Select a subscriber</option>
                  {subscribers.map((subscriber) => (
                    <option key={subscriber.id} value={subscriber.id}>{subscriber.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group>
                <Form.Label>Alias subscriber 1 for subscriber 2:</Form.Label>
                <Form.Control value={aliasSubscriber1ForSubscriber2} onChange={(event) => setAliasSubscriber1ForSubscriber2(event.target.value)} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Alias subscriber 2 for subscriber 1:</Form.Label>
                <Form.Control value={aliasSubscriber2ForSubscriber1} onChange={(event) => setAliasSubscriber2ForSubscriber1(event.target.value)} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Ownership</Form.Label>
                <Form.Select value={ownership} onChange={(event) => setOwnership(event.target.value)}>
                  <option value='0'>Both</option>
                  <option value='1'>Subscriber 1</option>
                  <option value='2'>Subscriber 2</option>
                </Form.Select>
              </Form.Group>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Select value={status} onChange={(event) => setStatus(event.target.value)}>
                  <option value='PENDING'>PENDING</option>
                  <option value='ACCEPTED'>ACCEPTED</option>
                  <option value='DECLINED'>DECLINED</option>
                </Form.Select>
              </Form.Group>
              { bond.id ? (
                <Button variant="primary" onClick={handleEditClick} className="mt-3">Confirm</Button>
              ) : (
                <Button variant="primary" onClick={handleAddClick} className="mt-3">Confirm</Button>
              ) }
                &nbsp;&nbsp;<Button variant="danger" onClick={handleOnCancel} className="mt-3">Cancel</Button>
            </Form>
          </Row>
        </Container>
      );
    }

    return null;
  }