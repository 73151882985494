import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import InvitationApi from '../apis/InvitationApi';

export const loadInvitations = createAsyncThunk("invitations/loadInvitations", async () => {
  const invitations = await InvitationApi.getInvitations();
  return invitations;
});

export const invitationSlice = createSlice({
    name: "invitations",
    initialState: {
      data: [ ],
      status: "idle",
      selectedInvitation: null,
      error: null,
    },
    reducers: {
      selectInvitation: (state, action) => {
        state.selectedInvitation = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(loadInvitations.pending, (state) => {
          state.status = "loading";
        })
        .addCase(loadInvitations.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.data = action.payload;
        })
        .addCase(loadInvitations.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        });
    }
});

export const selectInvitationsData = (state) => state.invitations.data;
export const selectInvitationsStatus = (state) => state.invitations.status;
export const selectSelectedInvitation = (state) => state.invitations.selectedInvitation;
export const { selectInvitation } = invitationSlice.actions;
export default invitationSlice.reducer;