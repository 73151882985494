import { Container, Row, Col } from 'react-bootstrap';
import LoginButton from './LoginButton';

const Unauthorized = () => {
    return (
        <Container>
            <Row className="justify-content-center align-items-center">
                <Col xs={12} md={6} lg={4}>
                    <div className="text-center">
                        <h2>You are not logged in</h2>
                        <LoginButton/>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Unauthorized;