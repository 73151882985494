import './App.css';
import Navigation from "./components/Navigation";
import { Container } from "react-bootstrap";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import User from './pages/User';
import Bond from './pages/Bond'
import Subscriber from './pages/Subscriber';
import SecureContainer from './pages/SecureContainer';
import Home from './pages/Home';
import AuthRoute from './components/AuthRoute';
import Invitation from './pages/Invitation';
import ExchangeConfiguration from './pages/ExchangeConfiguration';


function App() {
  return (
    <Router>
      <Navigation/>
      <div id='error-alert'></div>      
      <Container style={{ margin: '4rem' }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/invitations" element={<AuthRoute render={() => ( <Invitation/> )}/>} />
          <Route path="/users" element={<AuthRoute render={() => ( <User/> )}/>} />
          <Route path="/subscribers" element={<AuthRoute render={() => ( <Subscriber/> )}/>} />
          <Route path="/bonds" element={<AuthRoute render={() => ( <Bond/> )}/>} />
          <Route path="/containers" element={<AuthRoute render={() => ( <SecureContainer/> )}/>} />
          <Route path="/configurations" element={<AuthRoute render={() => ( <ExchangeConfiguration/> )}/>} />
        </Routes>
      </Container>  
    </Router>
  );
}

export default App;
