import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UserApi from '../apis/UserApi';

export const loadUsers = createAsyncThunk("users/loadUsers", async () => {
  const users = await UserApi.getUsers();
  return users;
});
export const updateUser = createAsyncThunk("users/updateUser", async (user) => {
  await UserApi.updateUser(user);
  return user;
});
export const addUser = createAsyncThunk("users/addUser", async (user) => {  
  const userCreated = await UserApi.addUser(user);
  return userCreated;
});
export const deleteUser = createAsyncThunk("users/deleteUser", async (userId) => {
  const users = await UserApi.deleteUser(userId);
  return users;
});

export const userSlice = createSlice({
  name: "users",
  initialState: {
    data: [ ],
    status: "idle",
    selectedUser: null,
    error: null,
  },
  reducers: {
    selectUser: (state, action) => {
      state.status = "idle";
      state.selectedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(loadUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUser.fulfilled, (state, action) => {        
        state.status = "succeeded";
        const idx = state.data.findIndex((u) => u.id === action.payload.id);
        if (idx !== -1) {
            state.data[idx] = action.payload;
        }
        state.selectedUser = null;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.selectedUser = null;
        state.data.push(action.payload);
      })
      .addCase(addUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });      
  },
});

export const selectUsersData = (state) => state.users.data;
export const selectUsersStatus = (state) => state.users.status;
export const selectSelectedUser = (state) => state.users.selectedUser;
export const { selectUser } = userSlice.actions;
export default userSlice.reducer;
