import axios from "axios";
import keycloak from "./keycloak";
import { Alert } from 'react-bootstrap';
import { createRoot } from 'react-dom/client';

// initializing the axios instance with custom configs
const api = axios.create({
  baseURL: window.REACT_APP_API_BASE_URL,  
});

// Define an error handler to log all errors except 401 errors
const errorHandler = (error) => {
  const statusCode = error.response?.status;

  if (statusCode && statusCode !== 401) {
    console.error(error.response?.data.message);
  }

  return Promise.reject(error);
};

// Add an interceptor to the "api" axios instance to add the Authorization header
// with the bearer token obtained from Keycloak
api.interceptors.request.use(async (config) => {
  // Get the access token from Keycloak
  // If the access token is available, add it to the headers
  const token = keycloak.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

// Add an interceptor to the "api" axios instance to handle errors
api.interceptors.response.use(undefined, (error) => {
  errorHandler(error)
    .catch((error) => {
      const container = document.getElementById('error-alert');
      const root = createRoot(container);  
      const alert = (
        <Alert variant="danger" onClose={() => console.log('Action to do when dimiss, i.e. clear states')} dismissible>
          {error.response.data.message}
        </Alert>
      );
      // Render the alert component to show the error message
      root.render(alert);
    });
});

// Export the "api" axios instance
export default api;
